#PrePermissionLogo {
    height: 40px;
    content: url(../images/WordMarkWhite.png);
}

#DashboardLogoWhite {
    &.pro {
        content: url(../images/WordMarkWhite.png);
    }
    &.enterprise {
        content: url(../images/WordMarkEnterpriseWhite.svg);
    }
}

#DashboardLogoWhite {
    content: url(../images/WordMarkWhite.png);
}

#DashboardLogoAdaptive {
    &.pro {
        &.dark {
            content: url(../images/WordMarkWhite.png);
        }
        &.light {
            content: url(../images/WordMarkBlack.png);
        }
    }
    &.enterprise {
        &.dark {
            content: url(../images/WordMarkEnterpriseWhite.svg);
        }
        &.light {
            content: url(../images/WordMarkEnterpriseBlack.svg);
        }
    }
}
