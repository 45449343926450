@import url('../fonts/fontawesome/css/all.min.css');

@font-face {
    font-family: 'Nocturne Serif';
    src: url('../fonts/NocturneSerif-Regular.woff') format('woff');
}

/* https://kovart.github.io/dashed-border-generator/ */
/* This did not work inline */
.bordered {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%2300000080' stroke-width='4' stroke-dasharray='4%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
}

.dashboard-uploader {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%237A5BDCFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
}

.flex-container-row {
    display: flex;
    justify-content: space-between;
}

.flex-container-row > p:first-child {
    flex: 1; /* take up all available space to the left */
    text-align: left; /* ensure text is aligned to the left */
}

.flex-container-row > p:last-child {
    flex: 1; /* take up all available space to the right */
    text-align: right; /* ensure text is aligned to the right */
}

.fade-enter {
    opacity: 0;
}
.fade-exit {
    opacity: 1;
}
.fade-enter-active {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
    transition: opacity 300ms;
}

/* These are for the CSSTransition component */

/* slide-enter, slide-exit, etc. are classNames passed to CSSTransition */

.slide-enter {
    transform: translateX(100%);
}

.slide-enter-active {
    transform: translateX(0);
    transition: transform 300ms;
}

.slide-exit {
    /* transform: translateX(0); */
    transform: translateX(100%);
    transition: transform 300ms;
}

.slide-exit-active {
    /* transform: translateX(-100%); */
    transform: translateX(100%);
    transition: transform 300ms;
}

.stack {
    position: relative;
}

.stack-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
